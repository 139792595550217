html,
body {
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

.font-base {
  font-family: "Open Sans", sans-serif;
}

.font-base-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

/* :root {
  --swiper-theme-color: #e31e24 !important;
} */

.title {
  flex-grow: 1;
}
.loader-container {
  display: flex;
  align-items: center;
  height: 80vh;
  justify-content: center;
}

.base-container {
  display: inline-block;
  margin-top: 60px;
  width: 100%;
}

.session-expiry-image {
  width: 200px;
  animation: zoominout 2s infinite;
}

.session-expiry-heading {
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  font-weight: bold;
}

.copyrightBar {
  background-color: #3e3e3e;
  color: white;
  text-align: center;
  padding: 10px 45px;
  font-size: 0.9rem;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoominout {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes zoominout {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes zoominout {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes zoominout {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 818px) {
  .long-menu {
    display: none;
  }
  .drawer {
    display: block;
  }
  .bottom-navigation {
    display: block;
  }
  .base-container {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 819px) {
  .drawer {
    display: none;
  }
  .long-menu {
    display: block;
  }
  .bottom-navigation {
    display: none;
  }
}
